a {
    color: theme-color(primary);
}

.auth-logo {
    width: 200px;
}

#view-page-table {
    table-layout: fixed;

    th, td { overflow: hidden; }
    .w5 { width: 5%; }
    .w7 { width: 7%; }
    .w8 { width: 8%; }
    .w10 { width: 10%; }
    .w12 { width: 12%; }
    .w15 { width: 15%; }
    .w20 { width: 20%; }
    .w25 { width: 25%; }
}